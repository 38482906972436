<template>
  <b-modal id="modal-teacher" hide-footer :title="`Asignar ${moduleType}`">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <iq-card>
          <template v-slot:body>
            <div class="new-user-info">
              <b-row class="justify-content-md-center">
                <b-form-group
                  class="col-md-12"
                  :label="`${moduleType}:`"
                  label-for="user"
                >
                  <ValidationProvider
                    name="user"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="form.id"
                      :placeholder="`${moduleType}`"
                      :options="users"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                      <template #option="{ firstname, lastname, email }">
                        <p style="margin: 0">
                          {{ `${firstname} ${lastname}` }}
                        </p>
                        <small>{{ email }}</small>
                      </template>
                      <template #no-options="{}"> Sin Resultados... </template>
                    </v-select>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-row>
              <hr />
              <b-button variant="success" class="float-right" type="submit"
                >{{ $t("app.save") }}</b-button
              >
              <b-button
                @click="$bvModal.hide('modal-teacher')"
                class="float-right mr-1"
                variant="outline-danger"
                >{{ $t("app.cancel") }}</b-button
              >
            </div>
          </template>
        </iq-card>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
export default {
  name: "modalForm",
  props: ["moduleType"],
  mounted() {
    core.index();
  },
  data() {
    return {
      users: [
        {
          id: 1,
          firstname: "Jose",
          lastname: "Perez",
          email: "j@gmail.com",
        },
        {
          id: 2,
          firstname: "Jose",
          lastname: "Hernandez",
          email: "abv@gmail.com",
        },
        {
          id: 3,
          firstname: "Jose",
          lastname: "Agraz",
          email: "afgsd@gmail.com",
        },
        {
          id: 4,
          firstname: "Jose",
          lastname: "Gonzalez",
          email: "bcd@gmail.com",
        },
        {
          id: 5,
          firstname: "Jose",
          lastname: "Bolivar",
          email: "cde@gmail.com",
        },
        {
          id: 6,
          firstname: "Jose",
          lastname: "Rojas",
          email: "def@gmail.com",
        },
        {
          id: 7,
          firstname: "Jose",
          lastname: "Rodriguez",
          email: "efg@gmail.com",
        },
        {
          id: 8,
          firstname: "Jose",
          lastname: "Garcia",
          email: "fgh@gmail.com",
        },
        {
          id: 9,
          firstname: "Jose",
          lastname: "Gomez",
          email: "ghi@gmail.com",
        },
      ],
      form: {
        id: null,
      },
    };
  },
  methods: {
    async show() {
      this.form = {
        id: null,
      };
      this.$bvModal.show("modal-teacher");
    },
    async onSubmit() {
      this.$bvModal.hide("modal-teacher");
      this.form = {
        id: null,
      };
    },
  },
};
</script>
